.info {
    position: relative;
    height: calc(100vh - 80px);
    min-height: 700px;
    text-align: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.content {
    position: relative;
    z-index: 1;
    color: #fff;
    width: 100%;
    max-width: 520px;
    max-height: fit-content;
    height: 100%;
    margin: auto ;
    padding: 20px 10px;
    box-shadow: 0 0 10px #fff4;
    background: linear-gradient(135deg, rgba(40, 23, 27, 0.65) 30%, rgba(25, 25, 25, 0.35) 50%, rgba(26, 23, 50, 0.75) 70%);
    border-radius: 80px;
}

.content img {
    width: 90%;
    max-width: 300px;
    margin: 20px auto;
    display: block;
    border-radius: 8px;
}
.content h2 {
    font-size: 3em;
    margin-bottom: 10px;
}

.content h3 {
    font-size: 2em;
    margin-top: 20px;
}

.content h5 {
    font-size: 1.1em;
    margin-top: 5rem;
}

.content p {
    font-size: 1.1em;
    margin: 20px 0;
    font-family: "MainFont", sans-serif;
}

.content img {
    width: 100%;
    max-width: 300px;
    margin: 20px auto;
    display: block;
    border-radius: 8px;
}
