
.overviewSearch {
    /*width: 90vw;*/
    /*margin-left: 5vw;*/
    /*background: #151515;*/
    height: 40px;
    text-align: center;
    font-size: 40px;

    position: relative;
    /*top: 0px;*/
    /*border-radius: 100px;*/

    outline: none;
    border-style: none;
    z-index: 1;

    justify-content: center;

    display: flex;

    overflow-x: scroll;
    scroll-direction: horizontal;
    scroll-behavior: smooth;
}
.overviewSearch ul{
    display: flex;
    align-items: center;
    list-style: none;
    max-width: 100%;
    overflow-x: scroll;
    scroll-behavior: auto;
}
.overviewSearch li{
    color: #fff;
    border-radius: 4px;
    white-space: nowrap;
}
