.overview{
    background: #141414;
    background: linear-gradient(135deg, rgba(27, 23, 27, 0.65) 0%, rgba(26, 23, 30, 0.95) 70%);
    z-index: 5;
    box-shadow: 0 0 10px #fff4;
}

.overviewHeader{
    font-size: 50px;

    /*z-index: 10;*/

    text-align: center;

    /*background: linear-gradient(90deg,*/
    /*        rgba(40, 40, 40, 0.55),*/
    /*        rgba(40, 40, 40, 0.6),*/
    /*        rgba(40, 40, 40, 0.55)*/
    /*),  url("/public/images/overview/Banner.png") center;*/
    background-size: cover;
    position: relative;
    /*filter: brightness(0.5);*/
}
.overviewHeader h1 {
    padding-top: 20vh;
    color: #eeeeee;
    text-shadow: rgba(15, 15, 15, 0.5) 0px 0px 30px;
}

.project-list-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    /*float-wrap: wrap;*/
    /*align-items: center;*/
    justify-content: center;
}

.project-wrapper {
    max-height: 1000px;
    max-width: 1000px;
    /*transition: max-height 1000ms ease, max-width 500ms ease;*/
    /*transition: opacity 1000ms linear, max-width 500ms linear, left 500ms linear;*/
    /*transition: left;*/
    /*transition: all 500ms ease;*/
    overflow-y: clip;
    overflow-x: clip;

    width: 32vw;
    float: left;



    padding-left: 1vw;
    padding-right: 1vw;
    padding-bottom: 3vh;

    /*opacity: 1;*/
}

.project-wrapper.project-wrapper-hidden  {
    /*transition: all 500ms ease;*/
    max-height: 0;
    max-width: 0;
    padding:0;
    margin: 0;
    /*opacity: 0;*/
}

@media screen and (max-width: 720px) {
    .project-wrapper {
        width: 95vw;
        /*margin-left: 2.5vw;*/
        padding: 0 0 12px;
    }
    .overviewHeader{
        font-size: 34px;
    }
}
